import { Controller } from "stimulus"
import consumer from '../../channels/consumer'

export default class extends Controller {
  initialize() {
    this.subscription()
  }
  disconnect() {
    this.subscription().unsubscribe()
    this.subscription().disconnected()
  }
  subscription() {
    if (this._subscription == undefined) {
      let _this = this
      this._subscription = consumer.subscriptions.create("SettingChannel", {
        connected() {},
        disconnected() {},
        received(data) {
        }
      })
    }
    return this._subscription
  }
  toggleLogin() {
    this.subscription().perform('toggle_login')
  }

  toggleHeatmap() {
    this.subscription().perform('toggle_heatmap')
  }

  toggleCheckin() {
    this.subscription().perform('toggle_checkin')
  }

  toggleRooms() {
    this.subscription().perform('toggle_rooms')
  }

  toggleChat() {
    this.subscription().perform('toggle_chat')
  }

  toggleQuestions() {
    this.subscription().perform('toggle_questions')
  }

  toggleFaq() {
    this.subscription().perform('toggle_faq')
  }

  togglePassword() {
    this.subscription().perform('toggle_password')
  }

  toggleSms() {
    this.subscription().perform('toggle_sms')
  }

  toggleRegistration() {
    this.subscription().perform('toggle_registration')
  }

  toggleRegistration() {
    this.subscription().perform('toggle_registration')
  }

  toggleUserUploads(){
    this.subscription().perform('toggle_user_uploads')
  }

  toggleImages(){
    this.subscription().perform('toggle_images')
  }

  toggleVoting(){
    this.subscription().perform('toggle_voting')
  }

  toggleSpeakers(){
    this.subscription().perform('toggle_speakers')
  }

  toggleMeetMeetando() {
    this.subscription().perform('toggle_meet_meetando')
  }
  toggleContact() {
    this.subscription().perform('toggle_contact')
  }

  toggleUserEmail() {
    this.subscription().perform('toggle_user_email')
  }

  toggleUserPhone() {
    this.subscription().perform('toggle_user_phone')
  }

  toggleUserDepartment() {
    this.subscription().perform('toggle_user_department')
  }
  toggleUserTitle() {
    this.subscription().perform('toggle_user_title')
  }
  toggleBroadcasts() {
    this.subscription().perform('toggle_broadcasts')
  }
  toggleTimer() {
    this.subscription().perform('toggle_timer')
  }
  toggleOnlyRegistration() {
    this.subscription().perform('toggle_only_registration')
  }

  toggleWhiteListEmails() {
    this.subscription().perform('toggle_white_list_emails')
  }
  toggleNewsletter() {
    this.subscription().perform('toggle_newsletter')
  }

  toggleCalendar() {
    this.subscription().perform('toggle_calendar')
  }
  togglePodium() {
    this.subscription().perform('toggle_podium')
  }

  toggleProgramFrontpage() {
    this.subscription().perform('toggle_program_frontpage')
  }
  toggleSpeakersFrontpage() {
    this.subscription().perform('toggle_speakers_frontpage')
  }
  toggleSpeakersLivepage() {
    this.subscription().perform('toggle_speakers_livepage')
  }

  toggleRoomlist() {
    this.subscription().perform('toggle_room_list')
  }

  toggleRoomDescription() {
    this.subscription().perform('toggle_room_description')
  }

  toggleShowSupportInfo() {
    this.subscription().perform('toggle_show_support_info')
  }

  toggleNoUserLogin() {
    this.subscription().perform('toggle_no_user_login')
  }

  toggleHybrid() {
    this.subscription().perform('toggle_hybrid')
  }

}
